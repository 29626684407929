import React from 'react'
import Layout from '../components/Layout'
import Nav from '../components/Nav'
import { Link } from "gatsby"

export default function contactMe() {
    return (
        <Layout>
            <Nav></Nav>
            <section className="section--container">
                <section className="section--scrollable">
                    <h1>Let's get in touch.</h1>
                    <p>You can reach out to me at <Link to="mailto:kevan.ling@gmail.com">kevan.lin@gmail.com</Link></p>
                </section>
            </section>
        </Layout>
    )
}